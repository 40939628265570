import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Strona główna | Witamy w Canvas of Colors</title>
        <meta name={"description"} content={"Twoja sztuka, Twoja historia"} />
        <meta
          property={"og:title"}
          content={"Strona główna | Witamy w Canvas of Colors"}
        />
        <meta
          property={"og:description"}
          content={"Twoja sztuka, Twoja historia"}
        />
        <meta
          property={"og:image"}
          content={"https://xovirel.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://xovirel.com/img/4285552.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://xovirel.com/img/4285552.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://xovirel.com/img/4285552.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://xovirel.com/img/1.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="700px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h1" font="--headline2">
            Canvas of Colors
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
            Witamy w Canvas of Colors, gdzie Twoja skóra staje się najlepszym
            płótnem do wyrażania siebie. Nasze przytulne i kreatywne studio
            tatuażu to idealne miejsce, w którym możesz ożywić swoje wizje.
            Niezależnie od tego, czy wykonujesz swój pierwszy tatuaż, czy chcesz
            uzupełnić swoją kolekcję, nasz zespół utalentowanych artystów jest
            tutaj, aby poprowadzić Cię przez każdy etap procesu w miłej i
            przyjaznej atmosferze.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-15"
      >
        <Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
        <Box
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 0px 16px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          lg-width="100%"
          justify-content="space-between"
        >
          <Box display="flex" flex-direction="column">
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline2"
              lg-text-align="center"
              sm-font="--headline3"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
              md-text-align="left"
            >
              Dlaczego warto wybrać nas?
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              lg-text-align="center"
              md-text-align="left"
            >
              Doświadczeni artyści: Nasz wykwalifikowany zespół pasjonuje się
              tatuowaniem i jest na bieżąco z najnowszymi technikami i stylami.
              <br />
              <br />
              Niestandardowe projekty: Specjalizujemy się w tworzeniu
              niestandardowych tatuaży, które odzwierciedlają Twoją wyjątkową
              osobowość i pomysły.
              <br />
              <br />
              Jakość i higiena: Twoje zdrowie i bezpieczeństwo są najważniejsze.
              Używamy sterylnego sprzętu i utrzymujemy najwyższe standardy
              higieny.
              <br />
              <br />
              Komfortowe środowisko: Zapewniamy, że wszyscy nasi klienci czują
              się jak w domu, niezależnie od tego, czy jest to Twój pierwszy
              tatuaż, czy dziesiąty.
            </Text>
          </Box>
        </Box>
        <Box
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
        >
          <Box
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            width="100%"
            position="relative"
            transform="translateY(0px)"
            padding="0px 0px 125% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
          >
            <Image
              src="https://xovirel.com/img/2.jpg"
              object-fit="cover"
              left={0}
              right={0}
              min-height="100%"
              position="absolute"
              display="block"
              width="100%"
              top="auto"
              bottom="0px"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
